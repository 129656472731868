.process-list-item {
  margin: 2rem 0;
  padding: 1rem;
  padding-left: 2rem;
  background: #afb3ba;
  border: 2px solid black;
  border-radius: 10px;
}

.heading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: inherit;
}

.number {
  font-family: 'Figtree', sans-serif;
  font-style: italic;
  font-weight: 800;
  font-size: 48px;
  padding: 0 1rem 0 0;
}

.text-container {
  margin-left: 1rem;
  padding-left: 2rem;
  border-left: 2px solid black;
}

.heading {
  font-size: 32px;
}

@media only screen and (max-width: 768px){
  .heading {
    font-size: 24px;
  }

  .number {
    font-size: 40px;
  }
}
