@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@700;800;900&display=swap');

.hero-full-width {
  padding: 0;
  height: 90vh;
  min-height: 855px;
  width: 100%;
  border-top: 3px solid black;
  background: url('../../assets/images/soccer-field-hero-image.jpg');
  background-size: cover;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .hero-full-width {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    align-items: unset;
  }
}

.hero-section-image {
  height: 100%;
  width: 50%;
}

.hero-image-container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.hero-image {
  height: 100%;
  object-fit: contain;
}

.title-container {
  width: fit-content;
  margin-left: 8rem;
}

.title-container h1 {
  font-family: 'Figtree', sans-serif;
  font-style: italic;
  font-weight: 800;
  font-size: 96px;
  word-spacing: 12px;
  text-transform: uppercase;
  width: 600px;
  margin: 0.6rem 0;
}


@media only screen and (max-width: 1024px) {
  .title-container {
    margin: 26rem auto 0 auto;
    text-align: left;
    padding-right: 1rem;
  }
  
  .title-container h1 {
    font-size: 72px;
    margin: 0 auto;
    width: fit-content;
    min-width: 450px;
  }
}

@media only screen and (max-width: 490px) {
  .title-container {
    padding-left: 1rem;
  }

  .title-container h1 {
    font-size: 64px;
  }
}

@media only screen and (max-width: 390px) {
  .title-container h1 {
    font-size: 56px;
  }
}

.about-section {
  padding: 4rem 10rem;
  background-color: #272A31;
  color: #fff;
}

@media only screen and (max-width: 1500px) {
  .about-section {
    padding: 4rem 4rem;
  }
}

@media only screen and (max-width: 1024px) {
  .about-section {
    padding: 3rem 2rem;
  }
}

.highlight {
  margin-top: 2rem;
}

.highlight h3 {
  background: #fff;
  color: #000;
  padding: 0.8rem 1rem;
  text-transform: uppercase;
  font-family: 'Figtree', sans-serif;
  font-style: italic;
  width: fit-content;
  word-spacing: 4px;
}

@media only screen and (max-width: 1180px) {
  .highlight h3 {
    max-width: 700px;
    text-align: center;
    margin: 1rem auto 0 auto;
  }
}

.landing-page-section-container {
  width: 100%;
}

.info-section {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
}

.intro {
  padding: 2rem 4rem;
  margin: 4rem 0;
  text-align: left;
  width: 50%;
}

.image-reversed {
  flex-direction: row-reverse;
}

.intro h2 {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1180px) {
  .intro {
    padding: 1rem 2rem;
  }

  .intro h2 {
    margin-bottom: 1rem;
  }
}

.read-more-btn {
  all: unset;
  cursor: pointer;
  margin-top: 2rem;
  padding: 12px 24px;
  background: #000;
  color: #fff;
  border: 1px solid #fff;
  font-family: 'Figtree', sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 24px;
  transition: background 0.3s;
}

.read-more-btn:hover {
  background: #fff;
  color: #000;
  border: 1px solid #000;
}

.info-image {
  width: 50%;
  height: inherit;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .info-section {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    height: fit-content;
    padding: 2rem 0 0 0;
  }

  .intro {
    width: fit-content;
    margin: 0 auto;
    text-align: center;
  }

  .info-image {
    width: auto;
    height: 400px;
    margin-top: 1rem;
  }
}