.staff-profile {
  padding: 2rem 0;
  border-bottom: 2px solid black;
}

.staff-profile:last-child {
  border: none;
  padding: 4rem 0;
}

.staff-profile p {
  margin-top: 1rem;
}

.image-container {
  margin-top: 2rem;
  display: flex;

}

.image-container img {
  width: 400px;
  margin: 20px;
}

.coaches-images-container {
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.coach-image {
  width: fit-content;
  max-width: 400px;
  max-height: 400px;
  object-fit: contain;
  margin: 1rem;
  border-radius: 10px;
}