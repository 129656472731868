@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400;500;600&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,200;1,300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  max-width: 2000px;
  margin: 0 auto;
}

h1, h2, h3, h4 {
  overflow-y: hidden;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
  font-family: 'Poppins', sans-serif;
}

h3 {
  font-size: 24px;
  font-family: 'Jost', sans-serif;
}

p {
  font-size: 18px;
  font-family: 'Jost', sans-serif;;
}

a {
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
  }
  
  h2 {
    font-size: 30px;
  }
}

.page {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.app-section {
  max-width: 1400px;
  margin: 0 auto;
}

.app-full-width {
  width: 100%;
}




