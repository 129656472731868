.footer {
  width: 100%;
  height: 8rem;
  border-top: 2px solid black;
  padding: 1rem 4rem;
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
}

.footer-logo {
  width: 70px;
  height: 70px;
}

.footer-contact {
  margin-left: 2rem;
  padding-left: 2rem;
  border-left: 2px solid black;
}

@media only screen and (max-width: 768px) {
  .footer{
    padding: 1rem;
  }
}