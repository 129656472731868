.header {
  padding: 1rem 0;
}

.registration-para {
  margin: 0rem;
}

.bold-text {
  font-weight: 800;
}

.cta-para {
  font-size: 26px;
  font-weight: 600;
  margin-top: 1rem;
}

.contact-us-section {
  margin-top: 2rem;
}

.contact-info h3 {
  margin-bottom: 0;
}

.email {
  color: #000;
}

.address {
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.address p {
  margin: 0;
}

.register-btn {
  all: unset;
  cursor: pointer;
  margin-top: 1rem;
  padding: 12px 24px;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  font-family: 'Figtree', sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 24px;
  transition: background 0.3s;
}

.register-btn:hover {
  background: #000;
  color: #fff;
  border: 1px solid #fff;
}

