.partnerships-page {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.section {
  margin-top: 2rem;
  margin-bottom: 6rem;
}
/* add media query here vs .app-section due to full width sections on this page */
@media only screen and (max-width: 1500px) {
  .section {
    padding: 0 2rem;
    margin-bottom: 4rem;
  }
}

.section p {
  margin-top: 2rem;
}

.gameday-section {
  padding: 4rem 2rem 6rem 2rem;
  margin-bottom: 4rem;
  background: #272A31;
}

.gameday-logo-container {
  width: 100%;
  text-align: center;
}

.gameday-logo-container img {
  width: 150px;
}

.gameday-table {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.gameday-card {
  padding: 2rem;
  margin: 1rem;
  width: 600px;
  background: #fff;
  border-radius: 10px;
}

@media only screen and (max-width: 1024px) {
  .gameday-table {
    flex-direction: column;
  }

  .gameday-card {
    width: auto;
  }
}

.gameday-heading {
  margin-bottom: 0.8rem;
}

.associated-clubs {
  text-align: center;
  background: #afb3ba;
  border-radius: 10px;
  padding: 4rem 3rem;
}

.associated-clubs-text-container {
  max-width: 1000px;
  margin: 0 auto;
}

.associated-clubs-text-container p {
  margin-top: 1rem;
}

.associated-clubs-logo-container {
  text-align: center;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #afb3ba;
  flex-wrap: wrap;
}

.associated-clubs-logo-container img {
  height: 240px;
}

.club-logo {
  padding: 2rem 4rem;
  margin: 2rem;
  border-radius: 5px;
  background: white;
}

.club-logo h3 {
  margin-top: 24px;
}

.team-highlight {
  font-weight: 600;
}

@media only screen and (max-width: 560px) {
  .associated-clubs-logo-container {
    padding: 1rem 0;
  }

  .club-logo {
    padding: 2rem 4rem;
    margin: 2rem 0;
    border-radius: 5px;
    background: white;
  }

  .associated-clubs-logo-container img {
    height: 200px;
  }
}
