.our-process {
  padding-top: 1rem;
}

.our-process-header {
  padding: 1rem 0;
}

@media only screen and (max-width: 1500px){
  .our-process-header {
    padding: 1rem 2rem;
  }
}

.our-process-header p {
  margin-top: 2rem;
}

.development-pillars-section {
  background: #272A31;
  margin-top: 2rem;
}

.development-pillars-container {
  text-align: center;
  margin: 2rem auto;
  padding-top: 2rem;
}

.development-pillars-container h2 {
  color: #fff;
}

.development-pillars {
  width: fit-content;
  margin: 1rem auto;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.pillar {
  margin:  1rem 2rem;
  padding: 2rem 3rem;
  border: 2px solid black;
  background: #afb3ba;
  border-radius: 2px;
  transform: skew(-10deg);
  text-align: left;
}

.pillar h3 {
  transform: skew(10deg);
  font-size: 28px;
}

.process-list-section {
  text-align: center;
  background: url('../../assets/images/soccer-field-3.jpg');
  background-size: cover;
  background-position: top;
}

.process-list-header {
  text-align: center;
  padding: 2rem 3rem;
  background: #afb3ba;
  border: 2px solid black;
  max-width: 820px;
  border-radius: 10px;
}

.process-list-header h2 {
  margin-bottom: 1rem;
}

.process-list {
  text-align: left;
  margin: 8rem auto;
  padding: 2rem;
  max-width: 820px;
  padding-bottom: 2rem;
} 

