.highlighted-players {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.player-highlight {
  margin: 4rem 0;
  padding: 2rem 3rem;
  border-radius: 10px;
  background: #272A31;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .highlighted-players {
    padding: 2rem 1rem 4rem 1rem;
  }

  .player-highlight {
    margin: 4rem 0;
    padding: 2rem;
  }
}

.player-highlight h2 {
  border-bottom: 2px solid white;
}

.player-highlight p {
  margin-top: 1rem;
}

.player-images-container {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .player-images-container {
    padding: 2rem 0;
  }
}

.player-image {
  width: fit-content;
  max-width: 400px;
  max-height: 400px;
  object-fit: contain;
  margin: 1rem;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .player-image {
    max-width: 370px;
    max-height: 370px;
  }
}

.split-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.player-text-container {
  width: 50%;
  padding-top: 1rem;
}

.player-image-container {
  width: 500px;
}

@media only screen and (max-width: 1200px) {
  .split-section {
    display: unset;
  }

  .player-text-container {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .player-image-container {
    width: fit-content;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 768px) {
  .split-section {
    display: flex;
    flex-direction: column;
    justify-content: unset;
    flex-wrap: unset;
  }
}
